import React from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withAlert } from "react-alert";
import axios from "axios";
import { SERVER_URL, getAuthStr, logoutUser } from "../utils";
const CHECKUP_ENDPOINT = `${SERVER_URL}/api/webAdmin/checkup`;
class Checkup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checkups: [],
      loading: true,
      logout: false
    };
  }

  async componentDidMount() {
    try {
      let response = await axios.get(CHECKUP_ENDPOINT, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({
          checkups: response.data,
          loading: false
        });
      }
    } catch (e) {
      this.setState({ error: true });
      this.props.alert.error("Request is failed!");
      if (e.response && e.response.status === 401) {
        this.props.alert.show("Unauthorized! Please login again!");
        localStorage.clear();
        this.setState({ logout: true });
      }
    }
  }

  render() {
    const styles = {
      columns: {
        subject: { width: "20%" },
        startData: { width: "20%" },
        endData: { width: "20%" },
        status: { width: "15%" },
        result: { width: "15%" }
      }
    };

    if (this.state.loading) {
      return (
        <React.Fragment>
          <PageTitle title="Checkup is Loading" />
          <div>
            <CircularProgress />
            {logoutUser(this.state.logout)}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <PageTitle title="Checkup" />
          <Grid container spacing={32}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.columns.subject}>
                      Subject
                    </TableCell>
                    <TableCell style={styles.columns.startData}>
                      Start Data
                    </TableCell>
                    <TableCell style={styles.columns.endData}>
                      End Data
                    </TableCell>
                    <TableCell style={styles.columns.status}>
                      <Link
                        className="button"
                        to="/app/checkup/newCheckup"
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          style={styles.newButton}
                        >
                          New Checkup
                        </Button>
                      </Link>
                    </TableCell>
                    <TableCell style={styles.columns.result} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.checkups.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={styles.columns.subject}>
                        {item.subject}
                      </TableCell>
                      <TableCell style={styles.columns.startData}>
                        {item.startDate.substring(0, 10)}
                      </TableCell>
                      <TableCell style={styles.columns.endData}>
                        {item.endDate.substring(0, 10)}
                      </TableCell>
                      <TableCell style={styles.columns.status}>
                        <Link
                          className="button"
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname: "/app/checkup/status",
                            state: { item }
                          }}
                        >
                          <Button color="primary">Status</Button>
                        </Link>
                      </TableCell>
                      <TableCell style={styles.columns.result}>
                        <Link
                          className="button"
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname: "/app/checkup/results",
                            state: { item }
                          }}
                        >
                          <Button color="primary">Results</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }
}

export default withAlert()(Checkup);
