import React from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class Status extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: []
    };
    this.getStatus = this.getStatus.bind(this);
  }

  componentDidMount() {
    this.setState({ status: this.props.location.state.item.users });
  }

  getStatus(statusCode) {
    let result = "Error";
    if (statusCode === 0) {
      result = "Invited";
    } else if (statusCode === 1) {
      result = "Accepted";
    } else if (statusCode === 2) {
      result = "Rejected";
    } else if (statusCode === 3) {
      result = "Completed";
    }
    return result;
  }

  render() {
    const styles = {
      columns: {
        name: {
          width: "20%"
        },
        loginId: {
          width: "30%"
        },
        status: {
          width: "10%"
        }
      },
      button: {
        marginTop: 30,
        float: "left"
      }
    };

    return (
      <React.Fragment>
        <PageTitle title="Checkup Status" />
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.columns.name}>
                    Display Name
                  </TableCell>
                  <TableCell style={styles.columns.loginId}>User Id</TableCell>
                  <TableCell style={styles.columns.status}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.status.map(item => (
                  <TableRow key={item.userId}>
                    <TableCell style={styles.columns.name}>
                      {item.displayName}
                    </TableCell>
                    <TableCell style={styles.columns.loginId}>
                      {item.loginId}
                    </TableCell>
                    <TableCell style={styles.columns.status}>
                      {this.getStatus(item.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <div style={styles.button}>
          <Link
            className="button"
            to="/app/checkup"
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary">
              Go Back
            </Button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Status;
