import React from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class Members extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      members: []
    };
  }

  componentDidMount() {
    this.setState({ members: this.props.location.state.item.users });
  }

  render() {
    const styles = {
      columns: {
        name: {
          width: "20%"
        },
        loginId: {
          width: "20%"
        },
        licenseStatus: {
          width: "20%"
        }
      },
      button: {
        marginTop: 30,
        float: "left"
      }
    };

    return (
      <React.Fragment>
        <PageTitle title="Study Group Members" />
        <Grid container spacing={8}>
          <Grid item xs={12}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={styles.columns.name}>Display Name</TableCell>
                  <TableCell style={styles.columns.loginId}>User Id</TableCell>
                  <TableCell style={styles.columns.licenseStatus}>License Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.members.map(item => {
                  let licenseStatusText = "No license";
                  if (item.licenseStatus === 1) {
                    licenseStatusText = "License added by church admin";
                  } else if (item.licenseStatus === 0) {
                    licenseStatusText = "Personal license";
                  }
                  return (
                    <TableRow key={item.userId}>
                      <TableCell style={styles.columns.name}>
                        {item.displayName}
                      </TableCell>
                      <TableCell style={styles.columns.loginId}>
                        {item.loginId}
                      </TableCell>
                      <TableCell style={styles.columns.licenseStatus}>
                        {licenseStatusText}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <div style={styles.button}>
          <Link
            className="button"
            to="/app/groups"
            style={{ textDecoration: "none" }}
          >
            <Button variant="contained" color="primary">
              Go Back
            </Button>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default Members;
