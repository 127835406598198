import React from "react";
import Button from "@material-ui/core/Button";
import { Redirect } from "react-router-dom";
import { withAlert } from "react-alert";
import axios from "axios";
import { SERVER_URL, getAuthStr } from "../utils";
const CREATE_ENDPOINT = `${SERVER_URL}/api/webAdmin/checkup/create`;
class CheckboxApply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
    this.getSelectMembers = this.getSelectMembers.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  getSelectMembers() {
    var users = [];
    for (let selectedRow of this.props.selectedRows.data) {
      let newMember = this.props.members[selectedRow.index].loginId;
      users.push(newMember);
    }
    return users;
  }

  async handleApply() {
    if (!this.props.subject || !this.props.endData) {
      this.props.alert.error("Error! Please fill all required fields!");
      return;
    }
    if (this.props.endData < this.props.startData) {
      this.props.alert.error("Error! The end data must after the start data!");
      return;
    }
    const payload = {
      subject: this.props.subject,
      startTime: this.props.startData + " 00:00:00",
      endTime: this.props.endData + " 23:59:59",
      users: this.getSelectMembers()
    };

    try {
      let response = await axios.post(CREATE_ENDPOINT, payload, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 201) {
        this.setState({ redirect: true });
        this.props.alert.success("Success!");
      }
    } catch (e) {
      this.props.alert.error("Error! Request is failed!");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/app/checkup" />;
    }

    const styles = {
      button: {
        marginRight: "240px",
        display: "inline-block",
        position: "relative"
      }
    };
    return (
      <div styles={styles.button}>
        <Button variant="contained" color="primary" onClick={this.handleApply}>
          Apply
        </Button>
      </div>
    );
  }
}

export default withAlert()(CheckboxApply);
