import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { withAlert } from "react-alert";

import axios from "axios";
import { getData } from "country-list";
import QueryString from "query-string";
import { CreditCard, PaymentForm } from "react-square-web-payments-sdk";
import { v4 as uuidv4 } from "uuid";

import { SERVER_URL, SQUARE_IDS } from "../utils";

const ORDER_ENDPOINT = `${SERVER_URL}/api/order`;

const TopProgress = () => (
  <LinearProgress style={{ position: "sticky", top: 5 }} />
)

class Order extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderData: {},
      requiredBillingContact: {
        givenName: "",
        email: "",
        address1: "",
        city: "",
        countryCode: "",
        postalCode: ""
      },
      optionalBillingContact: {},
      requiredFieldError: {},
      priceString: "",
      authorized: false,
      loading: true
    };
    this.queryParams = QueryString.parse(this.props.location.search);
    // idempotency key is created in constructor since app webview screen will pop on any error
    this.idempotencyKey = uuidv4();
  }

  async componentDidMount() {
    try {
      const getPriceUrl = `${ORDER_ENDPOINT}/${this.queryParams.lessonId}/${this.queryParams.orderType}/price`;
      let response = await axios.get(getPriceUrl, {
        headers: {
          Authorization: `Bearer ${this.queryParams.token}`,
          lang: this.queryParams.lang
        }
      });
      if (response.status === 200) {
        this.setState({
          orderData: response.data,
          priceString: (response.data.priceUSDCents / 100).toFixed(2),
          authorized: true,
          loading: false
        });
      }
    } catch (e) {
      this.handleRequestError(e);
    }
  }

  handleRequestError = (error) => {
    this.setState({ loading: false, error: true });

    this.props.alert.error("Error! Request is failed!");
    if (error.response) {
      if (error.response.status === 401) {
        this.props.alert.show("Unauthorized! Please login again!");
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage("Unauthorized! Please login again!");
        localStorage.clear();
      } else {
        let errMsg = `HTTP status ${error.response.status}`;
        if (error.response.data) {
          errMsg += `\n\n${JSON.stringify(error.response.data)}`;
        }
        this.props.alert.error(errMsg);
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(errMsg);
      }
    }
    return Promise.reject();
  }

  cardTokenizeResponseReceived = (token, buyer) => {
    this.setState({ loading: true });
    axios.post(`${ORDER_ENDPOINT}/processOrder`, {
      lessonId: this.queryParams.lessonId,
      orderType: this.queryParams.orderType,
      priceUSDCents: this.state.orderData.priceUSDCents,
      currency: this.state.orderData.currency,
      token: token.token,
      verificationToken: buyer.token,
      idempotencyKey: this.idempotencyKey,
      billingContact: { ...this.state.requiredBillingContact, ...this.state.optionalBillingContact }
    }, {
      headers: {
        Authorization: `Bearer ${this.queryParams.token}`,
        lang: this.queryParams.lang
      }
    }).catch(this.handleRequestError).then((response) => {
      this.setState({ loading: false });
      if (response.status === 200) {
        this.props.alert.success("The series has been successfully ordered!");
        const msg = {
          status: response.status,
          ...response.data
        };
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage(JSON.stringify(msg));
      }
    });
  }

  createVerificationDetails = () => {
    // create addressLines property
    const billingContact = {
      ...this.state.requiredBillingContact,
      ...this.state.optionalBillingContact,
      "addressLines": [this.state.requiredBillingContact.address1]
    };
    if (billingContact["address2"]) {
      billingContact["addressLines"].push(billingContact["address2"]);
    }
    delete billingContact.address1;
    delete billingContact["address2"];

    return {
      amount: this.state.priceString,
      currencyCode: this.state.orderData.currency,
      intent: "CHARGE",
      billingContact
    }
  }

  BillingContactTextField = (props) => {
    let helperText = " ";
    let error = false;
    if (props.required && this.state.requiredFieldError[props.id]) {
      helperText = "Required";
      error = true;
    }

    return <TextField
      variant="outlined"
      style={{ width: "100%" }}
      margin="dense"
      onChange={(event) => {
        const value = event.target.value;
        this.setState((prevState) => {
          const newState = prevState;
          const field = props.id;
          if (props.required) {
            newState.requiredBillingContact[field] = value;
            newState.requiredFieldError[field] = !value.length;
          } else {
            newState.optionalBillingContact[field] = value || undefined;
          }
          return newState;
        });
      }
      }
      helperText={helperText}
      error={error}
      {...props}
    />
  };

  render() {
    if (this.state.loading && !this.state.authorized) {
      return <TopProgress />;
    }
    return (this.state.authorized && (
      <>
        {this.state.loading && <TopProgress />}

        <this.BillingContactTextField id="givenName" label="Given name" required />
        <this.BillingContactTextField id="familyName" label="Surname" />
        <this.BillingContactTextField id="email" label="Email" required />
        <this.BillingContactTextField id="phone" label="Phone number" />
        <this.BillingContactTextField id="address1" label="Address" required />
        <this.BillingContactTextField id="address2" label="Apt, suite, unit, building, floor, etc." />
        <this.BillingContactTextField id="city" label="City" required />
        <this.BillingContactTextField id="state" label="State/Province/Region" />
        <this.BillingContactTextField id="postalCode" label="ZIP code/Postal code" required />
        <Autocomplete
          id="countryCode"
          options={getData()}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <this.BillingContactTextField {...params} id="countryCode" label="Country" required onChange={null} />
          )}
          onChange={(event, value) => {
            this.setState((prevState) => {
              const newState = prevState;
              newState.requiredBillingContact["countryCode"] = value?.code ?? "";
              newState.requiredFieldError["countryCode"] = !value?.code.length;
              return newState;
            });
          }}
        />
        <PaymentForm
          applicationId={SQUARE_IDS.APPLICATION_ID_PRODUCTION}
          locationId={SQUARE_IDS.LOCATION_ID_PRODUCTION}
          cardTokenizeResponseReceived={this.cardTokenizeResponseReceived}
          createVerificationDetails={this.createVerificationDetails}
          formProps={{ style: { marginBottom: 100 } }}
        >
          <CreditCard
            buttonProps={{
              isLoading: Object.values(this.state.requiredBillingContact).some((element) => element?.length === 0)
            }}
          >Pay ${this.state.priceString}</CreditCard>
        </PaymentForm>
      </>
    ));
  }
}

export default withAlert()(Order);
