import React from "react";
import { Grid } from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CircularProgress from "@material-ui/core/CircularProgress";
import { withAlert } from "react-alert";
import axios from "axios";
import { SERVER_URL, getAuthStr, logoutUser } from "../utils";
const GROUPS_ENDPOINT = `${SERVER_URL}/api/webAdmin/groups`;

class Groups extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      loading: true,
      logout: false
    };
    this.getLeaders = this.getLeaders.bind(this);
  }

  getLeaders(users) {
    let result = "";
    for (let user of users) {
      if (user.isGroupLeader === 1) {
        result += user.displayName + ";  ";
      }
    }
    return result.substring(0, result.length - 3);
  }

  async componentDidMount() {
    try {
      let response = await axios.get(GROUPS_ENDPOINT, {
        headers: { Authorization: getAuthStr() }
      });
      if (response.status === 200) {
        this.setState({
          groups: response.data,
          loading: false
        });
      }
    } catch (e) {
      this.setState({ error: true });
      this.props.alert.error("Error! Request is failed!");
      if (e.response && e.response.status === 401) {
        this.props.alert.show("Unauthorized! Please login again!");
        localStorage.clear();
        this.setState({ logout: true });
      }
    }
  }

  render() {
    const styles = {
      columns: {
        name: { width: "20%" },
        class: { width: "40%" },
        capacity: { width: "5%" },
        available: { width: "5%" },
        leader: { width: "20%" },
        edit: { width: "5%" },
        members: { width: "5%" }
      }
    };

    if (this.state.loading) {
      return (
        <React.Fragment>
          <PageTitle title="Study Groups is Loading" />
          <div>
            <CircularProgress />
            {logoutUser(this.state.logout)}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <PageTitle title="Study Groups" />
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.columns.name}>
                      Group Name
                    </TableCell>
                    <TableCell style={styles.columns.class}>
                      Class Name
                    </TableCell>
                    <TableCell style={styles.columns.capacity}>
                      Group Capacity
                    </TableCell>
                    <TableCell style={styles.columns.available}>
                      Available Seats
                    </TableCell>
                    <TableCell style={styles.columns.leader}>Leader</TableCell>
                    <TableCell style={styles.columns.edit} />
                    <TableCell style={styles.columns.members} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.groups.map(item => (
                    <TableRow key={item.groupId}>
                      <TableCell style={styles.columns.name}>
                        {item.groupName}
                      </TableCell>
                      <TableCell style={styles.columns.class}>
                        {item.className}
                      </TableCell>
                      <TableCell style={styles.columns.capacity}>
                        {item.capacity}
                      </TableCell>
                      <TableCell style={styles.columns.available}>
                        {item.available}
                      </TableCell>
                      <TableCell style={styles.columns.leader}>
                        {this.getLeaders(item.users)}
                      </TableCell>
                      <TableCell style={styles.columns.edit}>
                        <Link
                          className="button"
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname: "/app/groups/leaders",
                            state: { item }
                          }}
                        >
                          <Button color="primary">Edit Leader(s)</Button>
                        </Link>
                      </TableCell>
                      <TableCell style={styles.columns.members}>
                        <Link
                          className="button"
                          style={{ textDecoration: "none" }}
                          to={{
                            pathname: "/app/groups/members",
                            state: { item }
                          }}
                        >
                          <Button color="primary">Members List</Button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }
}

export default withAlert()(Groups);
