export const SERVER_URL = "https://app.7-stages.org";
export const SQUARE_IDS = Object.freeze({
  APPLICATION_ID_SANDBOX: "sandbox-sq0idb-cT5A-wM2qKFCVFSausfcxA",
  LOCATION_ID_SANDBOX: "GJ5SVT1XGH77P",
  APPLICATION_ID_PRODUCTION: "sq0idp-lzwHdFdVEeNucjHi5tpLxQ",
  LOCATION_ID_PRODUCTION: "N1JPYFXNZ5H5W"
});

export function getAuthStr() {
  return "Bearer ".concat(localStorage.getItem("acces_token"));
}

export function logoutUser(logout) {
  if (logout) {
    setTimeout(() => document.location.reload(true), 3000);
  }
}
