import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import themes, { overrides } from "../themes";
import Layout from "./Layout";
import Error from "../pages/error";
import Login from "../pages/login";

// pages
import Dashboard from "../pages/dashboard";
import Checkup from "../pages/checkup";
import Groups from "../pages/groups";
import Members from "../pages/groups/members";
import Leaders from "../pages/groups/leaders";
import Results from "../pages/checkup/results";
import Status from "../pages/checkup/status";
import NewCheckup from "../pages/checkup/newCheckup";
import Order from "../pages/order";

import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

const theme = createTheme({ ...themes.default, ...overrides });

const PrivateRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("acces_token") ? (
          React.createElement(component, props)
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem("acces_token") ? (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        ) : (
            React.createElement(component, props)
          )
      }
    />
  );
};

// optional cofiguration for alert
const options = {
  position: "bottom center",
  timeout: 5000,
  offset: "30px",
  transition: "scale"
};

const App = () => (
  <MuiThemeProvider theme={theme}>
    <BrowserRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PrivateRoute path="/app/dashboard" component={Dashboard} />
        <PrivateRoute path="/app/groups/leaders" component={Leaders} />
        <PrivateRoute path="/app/groups/members" component={Members} />
        <PrivateRoute path="/app/groups" component={Groups} />
        <PrivateRoute path="/app/checkup/status" component={Status} />
        <PrivateRoute path="/app/checkup/results" component={Results} />
        <PrivateRoute path="/app/checkup/newCheckup" component={NewCheckup} />
        <PrivateRoute path="/app/checkup" component={Checkup} />
        <PublicRoute path="/login" component={Login} />
        <AlertProvider template={AlertTemplate} {...options}>
          <PublicRoute path="/order" component={Order} />
        </AlertProvider>
        <Route component={Error} />
      </Switch>
    </BrowserRouter>
  </MuiThemeProvider>
);

export default App;
