import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import Widget from "../../../../components/Widget";
import { Typography } from "../../../../components/Wrappers";

class BigStat extends PureComponent {
  state = { value: "value" };

  changeValue = event => {
    this.setState({ value: event.target.value });
  };

  render() {
    const {
      title,
      name,
      classes
    } = this.props;
    const { value } = this.state;

    return (
      <Widget
        header={
          <div className={classes.title}>
            <Typography variant="h5">{title}</Typography>
          </div>
        }
        upperTitle
      >
        <div className={classes.totalValueContainer}>
          <div className={classes.totalValue}>
            <Typography size="xxl" color="textSecondary">
              {name[value]}
            </Typography>
          </div>
        </div>
      </Widget>
    );
  }
}

const styles = theme => ({
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    marginBottom: theme.spacing.unit
  },
  statCell: {
    padding: theme.spacing.unit * 2
  },
  totalValueContainer: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between"
  },
  totalValue: {
    display: "flex",
    alignItems: "baseline"
  },
});

export default withStyles(styles, { withTheme: true })(BigStat);
