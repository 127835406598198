import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import PageTitle from "../../components/PageTitle";
import BigStat from "./components/BigStat/BigStat";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoData: [
        {
          title: "Welcome to",
          name: { value: localStorage.getItem("churchName") }
        }
      ]
    };
  }

  render() {
    return (
      <React.Fragment>
        <PageTitle title="Home" />
        <Grid container spacing={32}>
          <Grid item xs={12}>
            {this.state.infoData.map(stat => (
              <Grid item md={4} sm={6} xs={12} key={stat.title}>
                <BigStat {...stat} />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = theme => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column"
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing.unit
  },
  progressSection: {
    marginBottom: theme.spacing.unit
  },
  progressTitle: {
    marginBottom: theme.spacing.unit * 2
  },
  progress: {
    marginBottom: theme.spacing.unit,
    backgroundColor: theme.palette.primary.main
  },
  legendItemContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing.unit
  },
  fullHeightBody: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between"
  },
  tableWidget: {
    overflowX: "auto"
  },
  progressBar: {
    backgroundColor: theme.palette.warning.main
  },
  performanceLegendWrapper: {
    display: "flex",
    flexGrow: 1,
    alignItems: "center",
    marginBottom: theme.spacing.unit
  },
  legendElement: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing.unit * 2
  },
  legendElementText: {
    marginLeft: theme.spacing.unit
  }
});

export default withStyles(styles, { withTheme: true })(Dashboard);
