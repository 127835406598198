import { compose } from "recompose";
import { connect } from "react-redux";

import { toggleSidebar } from "./LayoutState";
import { signOut } from "../../pages/login/LoginState";

import LayoutView from "./LayoutView";

export default compose(
  connect(
    state => ({
      isSidebarOpened: state.layout.isSidebarOpened,
      isAuthenticated: state.login.isAuthenticated
    }),
    { toggleSidebar, signOut }
  )
)(LayoutView);
