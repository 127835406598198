import axios from "axios";
import { SERVER_URL, getAuthStr } from "../utils";
const LOGIN_ENDPOINT = `${SERVER_URL}/api/user/login`;
const REFRESH_ENDPOINT = `${SERVER_URL}/api/user/refreshAccessToken`;

export const initialState = {
  isLoading: false,
  isAuthenticated: !!localStorage.getItem("acces_token"),
  error: null
};

export const START_LOGIN = "Login/START_LOGIN";
export const LOGIN_SUCCESS = "Login/LOGIN_SUCCESS";
export const LOGIN_FAILURE = "Login/LOGIN_FAILURE";
export const RESET_ERROR = "Login/RESET_ERROR";
export const LOGIN_USER = "Login/LOGIN_USER";
export const SIGN_OUT_SUCCESS = "Login/SIGN_OUT_SUCCESS";

export const startLogin = () => ({
  type: START_LOGIN
});

export const loginSuccess = userData => ({
  type: LOGIN_SUCCESS,
  payload: userData
});

export const loginFailure = () => ({
  type: LOGIN_FAILURE
});

export const resetError = () => ({
  type: RESET_ERROR
});

export const loginUser = (login, password) => async dispatch => {
  dispatch(startLogin());
  try {
    let data = { loginId: login, password: password };
    let response = await axios.post(LOGIN_ENDPOINT, data);
    if (response.status === 200) {
      if (response.data.isChurchAdmin === 1) {
        localStorage.setItem("displayName", response.data.displayName);
        localStorage.setItem("churchName", response.data.churchName);
        localStorage.setItem("acces_token", response.data.accessToken);
        var currentLoginTime = new Date();
        var tmp = localStorage.getItem("loginTime");
        var lastLoginTime = new Date(tmp);
        if (
          tmp !== null &&
          lastLoginTime.getDate() - currentLoginTime.getDate() > 7
        ) {
          let response = await axios.post(REFRESH_ENDPOINT, null, {
            headers: { Authorization: getAuthStr() }
          });
          localStorage.setItem("acces_token", response.data.accessToken);
        }
        localStorage.setItem("loginTime", currentLoginTime);
        dispatch(loginSuccess(response.config.data));
      }
    }
  } catch (e) {
    dispatch(loginFailure());
  }
};

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS
});

export const signOut = () => dispatch => {
  localStorage.removeItem("acces_token");
  dispatch(signOutSuccess());
};

export default function LoginReducer(state = initialState, { type, payload }) {
  switch (type) {
    case START_LOGIN:
      return {
        ...state,
        isLoading: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        error: null
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true
      };
    case RESET_ERROR:
      return {
        error: false
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false
      };
    default:
      return state;
  }
}
